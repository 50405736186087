<template>
  <div class="container">
    <div class="containCenter" v-if="!num">
      <sz-mode szType="grid" :bg="'left_bg'" position="left-top" width="23.5%" height="12%" lineShow="true">
        <template #grid>
          <div class="flex_center_align flex_item">
            <div class="flex_colum flex_item_col">
              <span class="flex_item_num">{{ eventForm.total }}</span>
              <span>总事件</span>
            </div>
            <div class="flex_colum flex_item_col">
              <span class="flex_item_num">{{ eventForm.finish }}</span>
              <span>今日已处理事件</span>
            </div>
            <div class="flex_colum flex_item_col">
              <span class="flex_item_num">{{ eventForm.unfinish }}</span>
              <span>待处理事件</span>
            </div>
          </div>
        </template>
      </sz-mode>
       <sz-mode szType="grid" :bg="'left_bg'" position="left-top" width="23.5%" height="38%" top="14.5%" lineShow="true">
        <template #grid>
          <div class="panel_item">
            <div class="panel_title">村委班子</div>
            <div class="flex_start flex_center_align cun_cheng">
              <div>班子成员</div>
              <img class="jinru" src="@/assets/img/jinru.png"  @click="showMode('村委班子','cunMembers')" />
            </div>
            <div style="padding: 10px 0;font-size: 18px;">
              <p style="text-indent: 15px;">小平房村委会是一个充满活力和团结互助且奋发向上的班子。村委会由一群充满热情和责任感的成员组成，他们都是小平房村的骨干人物。</p>
              <p style="text-indent: 15px;">村委书记是一位经验丰富、深受村民信任的领导者。他具有良好的组织能力和决策能力，善于倾听村民的意见和建议，并积极推动各项工作的开展。在他的带领下，整个村委会团结一心，共同努力为村民谋福利。
              除了书记之外，村委会还设有副书记和各个部门负责人。副书记在书记的指导下协助处理各项工作。各个部门负责人负责具体的工作领域，如农业发展、基础设施建设、教育医疗等，他们都是在各自领域内有一定经验和专业知识的人才。他们密切关注村民的需求，制定合理的工作计划，并组织人员积极落实。</p>
           </div>
          </div>
        </template>
      </sz-mode>
      <!-- 自然信息 -->
      <div v-for="(item, index) in dataList" :key="index">
        <sz-mode class="zrszmode" :bg="'left_bg'" :dIndex="index" position="left-bottom" width="23.5%" height="45%" :goInfoTemp="item.enterable == '1' ? true : false">
          <template #index>
            <div class="panel_item">
              <div class="flex_space flex_center_align">
                <div class="panel_title">自然信息</div>
                <template v-if="item.enterable == '1'">
                  <img class="jinru" src="@/assets/img/jinru.png" alt="" @click="goInfo(index, item.title)" />
                </template>
              </div>
              <div class="zrxx_box flex_colum">
                <div v-for="(items, index) in item.detailList" :key="index">
                  <div class="zrxx_item flex_start flex_center_align" v-if="items.elementValue.elementType == 'link' || items.elementValue.elementType == 'text'">
                    <div :class="['zr_left', items.elementValue.value ? '' : 'title']" :style="{ width: items.elementValue.elementType == 'link' ? '100%' : '', color: items.color }" v-if="items.elementValue.key">
                      {{ items.elementValue.key }}
                    </div>
                    <div class="zr_right" v-if="items.elementValue.value" :style="{ maxWidth: items.elementValue.key ? '65%' : '100%' }">
                      {{ items.elementValue.value }}
                    </div>
                    <img class="jinru" src="@/assets/img/jinru.png" alt="" v-if="items.elementValue.elementType == 'link'" @click="goDetailInfo(items)" />
                  </div>
                  <pie-chart height="150px" v-bind="items.elementValue" v-if="items.elementValue.elementType == 'pie'"></pie-chart>
                  <line-chart v-bind="items.elementValue" v-if="items.elementValue.elementType == 'line'"></line-chart>
                  <more-line-chart v-bind="items.elementValue" v-if="items.elementValue.elementType == 'morelines'"></more-line-chart>
                  <colum-chart v-bind="items.elementValue" v-if="items.elementValue.elementType == 'bar'"></colum-chart>
                  <template v-if="items.elementValue.elementType == 'iframe'">
                    <div class="word ql-editor" v-html="items.elementValue.articleContent" style="white-space:pre-wrap;"></div>
                  </template>
                  <!-- 多选 -->
                  <template v-if="items.elementValue.elementType == 'checkBox' || items.elementValue.elementType == 'checkMode'">
                    <div class="checkbox flex_colum">
                      <div class="flex_start inner flex_center_align " style="width:auto" v-for="(it, index) in items.elementValue.checkList" :key="index">
                        <div class="flex_start linebox2" @click="changeMap(it, items.elementValue.elementType)">
                          <div class="line" :class="{ active: it.id == changerow.id }"></div>
                          <div class="title">{{ it.title }}</div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <!-- 监控 -->
                  <template v-if="items.elementValue.elementType == 'media'">
                    <div class="checkbox flex_colum">
                      <div class="flex_start inner flex_center_align " style="width:auto">
                        <div class="flex_start linebox2" @click="changeMap(items.elementValue)">
                          <div class="line" :class="{ active: items.elementValue.id == changerow.id }"></div>
                          <div class="title">{{ items.elementValue.title }}</div>
                        </div>
                        <img class="videoIcon" :src="require('@/assets/img/' + 'video.png')" alt="" @click="openVideo(items.elementValue)" />
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </sz-mode>
      </div>

      <sz-mode szType="grid" :bg="'left_bg'" position="right-top" width="23.5%" height="28%" lineShow="true">
        <template #grid>
          <div class="panel_item">
            <div class="panel_title">人口统计</div>
            <div class="flex_center_align flex_item" style="margin: 0 0;">
              <div class="flex_colum flex_item_col">
                <span class="flex_item_num font" @click="showMode('总户数','familyNum')">{{ totalNums.familyNum }}户</span>
                <span class="font1">总户数</span>
              </div>
              <div class="flex_colum flex_item_col">
                <span class="flex_item_num font" @click="showMode('总人口数','residentNum')">{{ totalNums.residentNum }}人</span>
                <span class="font1">总人口数</span>
              </div>
              <div class="flex_colum flex_item_col">
                <span class="flex_item_num font" @click="showMode('网格员','griders')">{{ totalNums.adminUserNum }}人</span>
                <span class="font1">网格员</span>
              </div>
            </div>
            <div class="flex_center_align flex_item">
              <div class="flex_colum flex_item_col">
                <span class="flex_item_num font" @click="showMode('常住人口','oftenNum')">{{ totalNums.oftenNum }}人</span>
                <span class="font1">常住人口</span>
              </div>
              <div class="flex_colum flex_item_col">
                <span class="flex_item_num font" @click="showMode('外来人口','foreignNum')">{{ totalNums.foreignNum }}人</span>
                <span class="font1">外来人口</span>
              </div>
              <div class="flex_colum flex_item_col">
                <span class="flex_item_num font" @click="showMode('流动人口','flowNum')">{{ totalNums.flowNum }}人</span>
                <span class="font1">流动人口</span>
              </div>
            </div>
            <div class="flex_center_align flex_item">
              <div class="flex_colum flex_item_col">
                <span class="flex_item_num font" @click="showMode('护林员','rangerNum')">{{totalNums.rangerNum}}人</span>
                <span class="font1">护林员</span>
              </div>
              <div class="flex_colum flex_item_col">
                <span class="flex_item_num font" @click="showMode('森林防火领导组','forestPreNum')">{{totalNums.forestPreNum||0}}人</span>
                <span class="font1">森林防火领导组</span>
              </div>
              <div class="flex_colum flex_item_col">
                <span class="flex_item_num font" @click="showMode('应急灭火队','impatientNum')">{{totalNums.impatientNum||0}}人</span>
                <span class="font1">应急灭火队</span>
              </div>
            </div>
          </div>
        </template>
      </sz-mode>
      <!-- 村史中心 -->
      <sz-mode szType="grid" :bg="'left_bg'" position="right-top" width="23.5%" lineShow="true" top="31%" height="48.5%">
        <template #grid>
          <div class="panel_item">
            <div class="panel_title">村史中心</div>
            <el-collapse  accordion>
              <el-collapse-item  v-for="(item, index) in cunHis" :title="item.year">
                <div class="his_list" v-for="(items, indexs) in item.value">
                  <div class="his_list_item" @click.stop="seeHis(index,indexs)">
                    <div class="list_item_title">{{ items.historyName }}</div>
                    <div class="list_item_date">{{ items.historyDate }}</div>
                  </div>
                </div>
<!--                 <div id="zhbar1" ref="zhbar1" style="width:100%;height:300px;margin-top:20px"></div>-->
              </el-collapse-item>
            </el-collapse>
          </div>
        </template>
      </sz-mode>
      <!--  -->
      <sz-mode szType="grid" :bg="'left_bg'" position="right-bottom" width="23.5%" height="17.5%" lineShow="true">
        <template #grid>
          <div class="panel_item">
            <div class="panel_title">特殊人群统计</div>
            <div class="flex_center_align flex_item">
              <div class="flex_colum flex_item_col" v-for="(item, index) in peopleForm" :key="index">
                <span class="flex_item_num">{{ item.value }}人</span>
                <span class="flex_item_family">{{ item.name }}</span>
              </div>
            </div>
          </div>
        </template>
      </sz-mode>
    </div>
    <div v-else-if="num > 0">
      <div class="containCenter">
        <div v-for="(item, index) in dataList" :key="index">
          <sz-mode :bg="'left_bg'" :dIndex="index" :position="item.position" width="23.5%" :height="item.position.indexOf('-') != '-1' ? modeHeightSmall : modeHeightLong" :icon="item.icon" :title="item.title" :goInfoTemp="item.enterable == '1' ? true : false">
            <template #index>
              <div class="zrxx_box flex_colum">
                <div v-for="(items, index) in item.detailList" :key="index">
                  <div class="zrxx_item flex_start flex_center_align" v-if="items.elementValue.elementType == 'link' || items.elementValue.elementType == 'text'">
                    <div :class="['zr_left', items.elementValue.value ? '' : 'title']" :style="{ width: items.elementValue.elementType == 'link' ? '100%' : '', color: items.color }" v-if="items.elementValue.key">
                      {{ items.elementValue.key }}
                    </div>
                    <div class="zr_right" v-if="items.elementValue.value" :style="{ maxWidth: items.elementValue.key ? '65%' : '100%' }">
                      {{ items.elementValue.value }}
                    </div>
                    <img class="jinru" src="@/assets/img/jinru.png" alt="" v-if="items.elementValue.elementType == 'link'" @click="goDetail(items)" />
                  </div>
                  <pie-chart height="150px" v-bind="items.elementValue" v-if="items.elementValue.elementType == 'pie'"></pie-chart>
                  <line-chart v-bind="items.elementValue" v-if="items.elementValue.elementType == 'line'"></line-chart>
                  <more-line-chart v-bind="items.elementValue" v-if="items.elementValue.elementType == 'morelines'"></more-line-chart>
                  <colum-chart v-bind="items.elementValue" v-if="items.elementValue.elementType == 'bar'"></colum-chart>
                  <template v-if="items.elementValue.elementType == 'iframe'">
                    <div class="word ql-editor" v-html="items.elementValue.articleContent" style="white-space:pre-wrap;"></div>
                  </template>
                  <!-- 多选 -->
                  <template v-if="items.elementValue.elementType == 'checkBox' || items.elementValue.elementType == 'checkMode'">
                    <div class="checkbox flex_colum">
                      <div class="flex_start inner flex_center_align " style="width:auto" v-for="(it, index) in items.elementValue.checkList" :key="index">
                        <div class="flex_start linebox2" @click="changeMap(it, items.elementValue.elementType)">
                          <div class="line" :class="{ active: it.id == changerow.id }"></div>
                          <div class="title">{{ it.title }}</div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <!-- 监控 -->
                  <template v-if="items.elementValue.elementType == 'media'">
                    <div class="checkbox flex_colum">
                      <div class="flex_start inner flex_center_align " style="width:auto">
                        <div class="flex_start linebox2" @click="changeMap(items.elementValue)">
                          <div class="line" :class="{ active: items.elementValue.id == changerow.id }"></div>
                          <div class="title">{{ items.elementValue.title }}</div>
                        </div>
                        <img class="videoIcon" :src="require('@/assets/img/' + 'video.png')" alt="" @click="openVideo(items.elementValue)" />
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </sz-mode>
        </div>
      </div>
    </div>

    <div id="container" class="divwxMap" style="width: 100%; height:100%"></div>

    <cun-mode ref="cunMode"></cun-mode>
    <!-- 网格员详细展示以及图标详细展示 -->
    <wg-mode ref="wgMode"></wg-mode>
    <!-- <t-footer type="grid"></t-footer> -->
  <num-mode ref="numMode"></num-mode>

    <t-footer :tabList="tabList" @tab="handlerTab"></t-footer>
  </div>
</template>

<script>
import WgMode from '@/components/wgMode.vue'
import tFooter from '@/components/footer.vue'
import SzMode from '@/components/SzMode.vue'
import CunMode from '@/components/cunMode.vue'
import NumMode from '@/components/numMode.vue'
import { option1, pieoption, baroptin } from '../utils/options'
import * as echarts from 'echarts'
import { getpqDetail, getIndexStatistics, getAnalysis, getMenus, getOthers, getHomeDetail, getHisItems, getAllMembers, getHisItemsGroupByYera } from '@/api/api.js'
import { getID } from '@/utils/index.js'
import ColumChart from '@/components/chart/ColumChart.vue'
import LineChart from '@/components/chart/LineChart.vue'
import MoreLineChart from '@/components/chart/MoreLineChart.vue'
import PieChart from '@/components/chart/PieChart.vue'
export default {
  components: {
    tFooter,
    SzMode,
    CunMode,
    WgMode,
    PieChart,
    LineChart,
    ColumChart,
    MoreLineChart,
    NumMode //人员展示窗
  },
  data() {
    return {
      wxMap: null,
      tianInfo: {},
      year: '',
      panelWidth: '100%',
      eventForm: {
        total: '500',
        finish: '300',
        unfinish: '200'
      },
      totalNums: { familyNum: 0, residentNum: 0, adminUserNum: 0, oftenNum: 0, foreignNum: 0, flowNum: 0, rangerNum: 0 },
      peopleForm: [{ value: 0, name: '留守妇女' }, { value: 0, name: '留守人员' }, { value: 0, name: '低保人员' }, { value: 0, name: '残疾人员' }],
      loading: false,
      baseForm: {},
      dataList: [],
      cunHis: [{ title: 'title' }],
      tabList: [],
      num: '',

      modeHeightSmall: '48%',
      modeHeightLong: '100%'
    }
  },
  mounted() {
    this.baseForm = JSON.parse(sessionStorage.getItem('userInfo'))
    this.$nextTick(() => {
      this.initMap()
      this.getgrids()
      this.getDetail()
      this.getMenus()
      //村史列表
      this.getItems()
    })
  },
  methods: {
    getItems() {
      getHisItemsGroupByYera({ villageId: this.baseForm.regionId, historyType: 0 }).then(res => {
        if (res.model.length > 0) {
          res.model.map(item => {
            item.value.map(items => {
              if (items.historyDate) items.historyDate = this.$moment(items.historyDate).format('YYYY-MM-DD')
            })
          })
        }
        this.cunHis = res.model
      })
      // getHisItems({ villageId: this.baseForm.regionId, historyType: 0, page: 1, rows: 10000 }).then(res => {
      //   if (res.code == '0') {
      //     if (res.items.length > 0) {
      //       res.items.map(item => {
      //         if (item.historyDate) item.historyDate = this.$moment(item.historyDate).format('YYYY-MM-DD')
      //       })
      //     }
      //     this.cunHis = res.items
      //   }
      // })
    },
    //网格员详细信息展示
    goDetail(item) {
      let data = item
      data.itemtype = 'grider'

      this.$refs.wgMode.initData(data)
      this.$refs.wgMode.wgList = [item]
    },
    //详情
    goDetailInfo(item) {
      console.log(item)

      this.$refs.cunMode.initData(item)
    },
    getDetail() {
      this.baseForm = JSON.parse(sessionStorage.getItem('userInfo'))
      getpqDetail({ villageId: this.baseForm.regionId, page: 1, rows: 10000 }).then(res => {
        if (res.code == '0') {
          let data = ''
          if (res.items.length) {
            res.items.map(item => {
              item.lgt = item.longitude
              item.lat = item.latitude
              item.mode = true
              item.type = 'gridType'
              // gridding --绘制区域需要
              if (item.introduction) item.mode = true
              item.icon = 'icon'
            })
          }
          data = res.items
          this.setCunMap(data)
        }
      })
    },
    initMap() {
      // vue项目需要先声明 T = window.T，不然后面无法获取到。
      let T = window.T
      this.wxMap = null
      let initMap = new T.Map('container') // div的id
      // initMap.addControl(window.TMAP_TERRAIN_HYBRID_MAP)

      // 传参中心点经纬度，以及放大程度，最小1，最大18
      // console.log(Number(this.baseForm.longitude))
      // initMap.centerAndZoom(
      //   new T.LngLat(Number(this.baseForm.longitude) || 119.97503, Number(this.baseForm.latitude) || 41.406052),
      //   12
      // )
      initMap.centerAndZoom(new T.LngLat(Number(this.baseForm.longitude), Number(this.baseForm.latitude)), 12.5)
      // initMap.centerAndZoom(new T.LngLat(119.97503, 41.406052), 12)
      // TMAP_NORMAL_MAP 此地图类型展示普通街道视图。
      // TMAP_SATELLITE_MAP 此地图类型展示卫星视图。
      // TMAP_HYBRID_MAP 此地图类型展示卫星和路网的混合视图。
      // TMAP_TERRAIN_MAP 此地图类型展示地形视图。
      // TMAP_TERRAIN_HYBRID_MAP 此地图类型展示地形和路网的混合视图。
      initMap.setStyle('black')
      //绘制边界线
      initMap.enableDrag()
      initMap.enableScrollWheelZoom()
      this.wxMap = initMap
      this.initBorder()
      //
    },

    setCunMap(data) {
      if (typeof data == 'string') {
        let list = []
        // console.log(this.dataList)

        this.dataList.forEach(item => {
          console.log('item.detailList', item.detailList);
          if (item.detailList.length > 0) {
            item.detailList.forEach(its => {
              if (its.elementValue.elementType == 'media' && its.elementValue.lat) {
                list.push(its)
              }
              if (its.elementValue.elementType == 'checkBox' && its.elementValue.checkList.length > 0) {
                its.elementValue.checkList.map(itss => {
                  if (itss.list.length > 0) {
                    list = list.concat(itss.list)
                  }
                })
              }
            })
          }
        })
        data = list
        // console.log(data)
        this.setMapSetting(data)

        this.initBorder()
        this.setRegion(data)
      } else {
        this.setMapSetting(data)

        this.initBorder()
        this.setRegion(data)
      }
    },
    getgrids() {
      this.loading = true
      /*右上角人口统计 */
      getIndexStatistics().then(res => {
        if (res.code == 0) {
          this.totalNums = res.items[0]
        }
      })
      this.drowAnalysis()
      this.loading = false
    },
    drowAnalysis() {
      let _this = this
      getAnalysis().then(res => {
        if (res.code == 0) {
          let initp = [{ value: 0, name: '留守妇女' }, { value: 0, name: '留守人员' }, { value: 0, name: '低保人员' }, { value: 0, name: '残疾人员' }]
          //困难人群
          _this.peopleForm = res.model?.analysisThree && res.model.analysisThree.length > 0 ? res.model.analysisThree : initp
        }
      })
    },
    //获取菜单
    getMenus() {
      let list = [{ tabName: '总览', value: 'index', id: '', active: false, icon: require('@/assets/img/tb1.png') }, { tabName: '3D地图', value: '3d', id: '', active: false, icon: require('@/assets/img/3D.png') }]
      this.tabList = list
      // this.indexList=[]
      //  this.dataList=[]
      getMenus({ regionId: this.baseForm.regionId }).then(res => {
        if (res.code == '0') {
          if (res.items && res.items.length) {
            let data = res.items.filter(i => {
              return i.tabName == '自然'
            })
            data[0].icon = require('@/assets/img/tb5.png')
            this.tabList = [...list, ...data]
            console.log(this.tabList)
          }
        }
      })
      //首页详情
      getHomeDetail({ regionId: this.baseForm.regionId }).then(res => {
        console.log(res)
        if (res.code == '0') {
          if (this.baseForm.regionId == '50') {
            //小平房
            var index = res.model.findIndex(value => value.title == '自然信息')
          }
          this.dataList = this.filterArr([res.model[index]])
          this.indexList = this.dataList
          // console.log()
          console.log(this.dataList)
        }
      })
    },
    filterArr(list) {
      let arr = list
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].detailList && arr[i].detailList.length) {
          arr[i].detailList.map(item => {
            if (item?.elementValue) {
              item.elementValue = JSON.parse(item.elementValue)
              if (item.elementValue.elementType == 'checkBox') {
                // 多选
                item.elementValue.checkList.forEach(el => {
                  el.id = getID()
                })
              }
              if (item.elementValue.elementType == 'media') {
                // 带有监控
                item.elementValue.list = []
                item.elementValue.list.push({
                  icon: 'dapeng',
                  lat: item.elementValue.lat,
                  lgt: item.elementValue.lgt,
                  name: item.elementValue.title,
                  articleContent: item.elementValue.articleContent
                })
              }
              if (item.elementValue.elementType == 'morelines') {
                //多折线
                item.elementValue.legend = []
                item.elementValue.yAxisData.map(it => {
                  item.elementValue.legend.push(it.name)
                  it.data = []
                  if (it.tagData.length) {
                    it.tagData.map(ii => {
                      if (ii?.num != undefined) {
                        it.data.push(ii.num)
                      }
                    })
                  }
                })
              }
            }
          })
        }
      }
      return arr
    },
    //菜单详情
    getDetatils(stid, parentId) {
      this.dataList = []
      console.log('stid', stid);
      console.log('parentId', parentId);
      getOthers({ stId: stid, parentId: parentId ? parentId : '' }).then(res => {
        if (res.code == '0') {
          console.log('res============>', res);
          this.dataList = this.filterArr(res.model)
          this.$nextTick(() => {
            this.clearMarker()
            this.setCunMap('ls')
            this.initBorder()
            this.changerow = ''
          })
        }
      })
    },
    goInfo(index, title) {
      this.num = this.tabList.length - 1
      this.changeTab(this.tabList.length - 1)
    },
    handlerTab(index) {
      this.$nextTick(() => {
        if (index != this.num) {
          this.num = index
          let value = this.tabList[index].value
          if (value == '3d') {
            this.$router.push({ name: '3dmap' })
          } else {
            this.changeTab(index)
          }
        }
      })
    },
    changeTab(index) {
      console.log(index)
      console.log(this.tabList)

      if (index == 0) {
        this.changerow = ''
        this.dataList = this.indexList
        this.clearMarker()
        this.getDetail()
      } else {
        this.dataList = []
        this.getDetatils(this.tabList[index].id)
      }
    },
    changeMap(item, type) {
      console.log('item')
      console.log(item)
      if (type == 'checkMode') {
        item.checked = !item.checked
        let data = item.list[0]
        data.itemtype = type
        this.$refs.cunMode.initData(data)
      } else {
        item.checked = !item.checked
        if (item.checked) {
          this.clearMarker(item)
          this.changerow = item
          let data = item.point || item.list
          this.pointData = []
          this.setMapSetting(data)
          this.initBorder()
        } else {
          this.clearMarker()
          this.setCunMap('ls')
          this.changerow = ''
        }
      }
    },

    seeHis(index, indexs) {
      let data = this.cunHis[index].value[indexs]
      data.itemtype = 'history'
      this.$refs.wgMode.initData(data)
    },

    showMode(name, type) {
      let item = { type: type, name: name, showType: '', regionId: this.baseForm.regionId }
      if (['cunMembers', 'griders', 'rangerNum', 'forestPreNum', 'impatientNum'].includes(type)) {
        item.showType = 'peopleImg'
      } else if (['residentNum', 'oftenNum', 'foreignNum', 'flowNum'].includes(type)) {
        item.showType = 'description'
      } else if (['familyNum'].includes(type)) {
        item.showType = 'tableList'
      }
      this.$refs.numMode.initData(item)
    }
  },
  created() {}
}
</script>

<style lang="scss" scped>
@import '../common/css/chart';
:root {
  --color: rgba(12, 89, 240, 0.7);
  --lineColor: rgba(102, 163, 224, 0.2);
}
.containCenter {
  .c_left {
    position: fixed;
    left: 40px;
    width: 23.5%;
  }
  .c_right {
    position: fixed;
    right: 40px;
    width: 23.5%;
  }
  .panel_item {
    margin: 5px 12px;
    .panel_title {
      font-family: 'Microsoft YaHei';
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
      text-shadow: 3px 1px 4px #3c8ccf;
      font-style: italic;
      letter-spacing: 5px;
    }
    .center_title {
      text-align: center;
    }
    .el-collapse-item__header {
      background-color: inherit !important;
      color: #fff !important;
      font-size: 18px;
    }
    .el-collapse-item__wrap {
      background-color: transparent !important;
    }
    .el-collapse-item__content {
      color: #fff !important;
    }
  }
  .flex_item {
    width: 100%;
    justify-content: space-evenly;
    text-align: center;
    flex-wrap: wrap;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    .flex_item_col {
      width: 30%;
      margin: 10px 0;
      flex: 1;
      color: #fff;
    }
    .flex_item_num {
      font-family: 'Microsoft YaHei';
      font-style: normal;
      font-weight: 400;
      font-size: 26px;
      cursor: pointer;
    }
  }
  .member {
    height: 105px;
    overflow-y: auto;
  }
  .member_mes {
    width: 100%;
    justify-content: space-evenly !important;
    margin: 2px 0;
    cursor: pointer;
  }
  /*网格员姓名 */
  .grid_member .member_mes .member_full_name {
    font-family: 'Source Han Sans CN';
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    /* color: #FFFFFF; */
  }

  /*网格员电话 */
  .grid_member .member_mes .member_mobile {
    font-family: 'Source Han Sans CN';
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    /* color: #FFFFFF; */
  }
  .his_list_item {
    padding: 10px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    cursor: pointer;
  }
  .list_item_title {
    width: 100%;
    font-size: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
  .list_item_date {
    font-size: 16px;
    text-align: right;
    color: #ccc;
  }
  .zrszmode {
    .new_con {
      width: 100%;
    }
  }
}

.tdt-label {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 8px;
  background-color: #515459e8 !important;
  border-color: rgb(116, 118, 121) !important;
}

.cun_cheng {
  padding-top: 10px;
  font-size: 20px;
  justify-content: space-between !important;
}

.font{
  font-size: 18px !important;
}
.font1{
  font-size: 12px !important;
}
</style>
